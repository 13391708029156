<template>
    <div class="cursor-default">
        <custom-button :class="additionalButtonClasses" v-if="isButton" @click.native="displayModal = true" :colour-type="buttonType">
            {{title ? title : action.title}}
        </custom-button>

      <a v-else class="cursor-pointer font-normal"  @click="displayModal = true" :data-cy="`action-button-${action.name}`">
        {{title ? title : action.title}}
      </a>
        <portal to="destination">
            <large-model v-show="displayModal" :submitting="submittingModal" :title="action.title"
                        :confirmationString='`Are you sure you want to \"${action.title}\"`'
                        @removeFromDisplay="displayModal = false" @perform="perform()" :confirm-button-text="title ? title : action.title">
                <div class="grid grid-cols-2 gap-2">
                    <div class="grid grid-cols-2 auto-rows-min">
                        <collapsable-header class="col-span-2" :collapsable="false" title="User Information">
                        </collapsable-header>
                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            First Name
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('first_name')">*</span>
                        </div>
                        <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="first_name"
                                :show-label="false"
                                v-model="formData['first_name']">
                            </property-or-field>
                            <div v-show="errors.has('first_name')" class="text-v3-red text-sm">{{ errors.first('first_name') }}</div>
                        </div>
                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            Last / Family Name
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('surname')">*</span>
                        </div>
                        <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="surname"
                                :show-label="false"
                                v-model="formData['surname']">
                            </property-or-field>
                            <div v-show="errors.has('surname')" class="text-v3-red text-sm">{{ errors.first('surname') }}</div>
                        </div>

                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            Email
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('email')">*</span>
                        </div>
                        <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="email"
                                :show-label="false"
                                v-model="formData['email']">
                            </property-or-field>
                            <div v-show="errors.has('email')" class="text-v3-red text-sm">{{ errors.first('email') }}</div>
                        </div>
                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            Password
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('password')">*</span>
                        </div>
                        <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="password"
                                :show-label="false"
                                v-model="formData['password']">
                            </property-or-field>
                            <div v-show="errors.has('password')" class="text-v3-red text-sm">{{ errors.first('password') }}</div>
                        </div>

                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            Contact Number
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('contact_number')">*</span>
                        </div>
                        <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="contact_number"
                                :show-label="false"
                                v-model="formData['contact_number']">
                            </property-or-field>
                            <div v-show="errors.has('contact_number')" class="text-v3-red text-sm">{{ errors.first('contact_number') }}</div>
                        </div>
                    </div>
                    <div class="grid grid-cols-2 auto-rows-min">
                        <collapsable-header class="col-span-2" :collapsable="false" title="Employment Details">
                        </collapsable-header>

                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            Roles
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('role_ids')">*</span>
                        </div>
                        <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="role_ids"
                                :show-label="false"
                                v-model="formData['role_ids']">
                            </property-or-field>
                            <div v-show="errors.has('role_ids')" class="text-v3-red text-sm">{{ errors.first('role_ids') }}</div>
                        </div>

                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            Payment Type
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('payment_type')">*</span>
                        </div>
                        <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="payment_type"
                                :show-label="false"
                                v-model="formData['payment_type']">
                            </property-or-field>
                            <div v-show="errors.has('payment_type')" class="text-v3-red text-sm">{{ errors.first('payment_type') }}</div>
                        </div>

                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            Payment Type Rate
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('hourly_rate')">*</span>
                        </div>
                        <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="hourly_rate"
                                :show-label="false"
                                v-model="formData['hourly_rate']">
                            </property-or-field>
                            <div v-show="errors.has('hourly_rate')" class="text-v3-red text-sm">{{ errors.first('hourly_rate') }}</div>
                        </div>

                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            Unique Taxpayer Reference
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('unique_taxpayer_reference')">*</span>
                        </div>
                        <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="unique_taxpayer_reference"
                                :show-label="false"
                                v-model="formData['unique_taxpayer_reference']">
                            </property-or-field>
                            <div v-show="errors.has('unique_taxpayer_reference')" class="text-v3-red text-sm">{{ errors.first('unique_taxpayer_reference') }}</div>
                        </div>

                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            Contract Start Date
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('contract_start_date')">*</span>
                        </div>
                        <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="contract_start_date"
                                :show-label="false"
                                v-model="formData['contract_start_date']">
                            </property-or-field>
                            <div v-show="errors.has('contract_start_date')" class="text-v3-red text-sm">{{ errors.first('contract_start_date') }}</div>
                        </div>

                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            Holidays Per Year
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('holidays_per_year')">*</span>
                        </div>
                        <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="holidays_per_year"
                                :show-label="false"
                                v-model="formData['holidays_per_year']">
                            </property-or-field>
                            <div v-show="errors.has('holidays_per_year')" class="text-v3-red text-sm">{{ errors.first('holidays_per_year') }}</div>
                        </div>


                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            Accounting Code
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('accounting_code')">*</span>
                        </div>
                        <div class="whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="accounting_code"
                                :show-label="false"
                                v-model="formData['accounting_code']">
                            </property-or-field>
                            <div v-show="errors.has('accounting_code')" class="text-v3-red text-sm">{{ errors.first('accounting_code') }}</div>
                        </div>
                    </div>
                </div>

                <div v-show="errors.has('items')" class="text-v3-red text-sm">{{ errors.first('items') }}</div>

            </large-model>
        </portal>
    </div>
</template>

<script>
import SlideOver from "@/v2/components/SlideOver";
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";
import LargeModel from "@/v3/models/LargeModel.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";
import BlankHeader from "@/v3/components/headers/BlankHeader.vue";
import Header from "@/v2/components/Header.vue";
import CollapsableHeader from "@/v3/components/headers/CollapsableHeader.vue";
import Siren from "super-siren";
import {mapGetters} from "vuex";
import CustomButton from "@/v3/Buttons/CustomButton.vue";

let follow = Siren.Helper.follow;
export default {

    inject: ["$validator"],
    name: "addUserForm",
    data() {
        return {
            displayModal: false,
            submittingModal: false,
            clientId: null,
            projectId: null,
            deliveryMethod: 'delivery'
        }
    },
    components: {
        CustomButton,
        CollapsableHeader,
        Header,
        BlankHeader,
        PropertyOrField,
        LargeModel,
        SlideOver,
        'a-button': Button
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        additionalButtonClasses: {
            type : String,
            default: null
        },
        buttonType:{
            type: String,
            default: 'primary'
        },
      isButton:{
          type: Boolean,
          default: true
      }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken"
        }),
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            // if(payload['client_id'] === undefined)
            // {
            //     Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            //     Siren.get(process.env.VUE_APP_API_URL + "/api/")
            //         .then(
            //             follow("current-user")
            //         )
            //         .then(res => {
            //             this.clientId = res.body.properties.get("client_id");
            //         }, this);
            // }
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            if(!this.submittingModal) {
                this.submittingModal = true;
                this.action.perform(this.formData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        this.$router.go(0);
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if (error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    } else if (error.status === 403 || error.status === 401) {
                        let errorMessage = "You do not have permission to " + this.action.title;
                        if (error.response.body.message) {
                            errorMessage = error.response.body.message;
                        }
                        alert(errorMessage);
                    } else {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        getField(fieldName){
            return this.action.fields.filter(field => field.name === fieldName).first();
        },
        isActionRequired(fieldId){
            if (!this.action) {
                return false;
            }
            let field = this.action.fields.filter(field => field.name === fieldId).first();
            if(field){
                if(field.validation != null) {
                    return field.validation.includes('required')
                }
            }
            return false;
        },
    },
}
</script>

<style scoped>

</style>
